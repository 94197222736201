export default {
  en: {
    hello: "Hello",
    errors: {
      min3char: "Name field should at least contains 3 letters",
      emailRegex: "Please enter a valid email format",
      minDateError: "The date must be in the future",
    },
    langBtn: "عربي",
    common: {
      rs: "SR",
      purchased: "Purchased",
      need: "Need",
      item: "Item",
      items: "Items",
      yes: "Yes",
      no: "No, thank you",
      buy:"Buy",
      link:"Link"
    },
    home: {
      content:
        "What is Lorem Ipsum?Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    },
    splashScreen: {
      content: "Wait for our website soon...",
    },
    registryList: {
      purchased: "Purchased",
      noGifts: "There are no gifts added yet",
      createWishList: "Create your own registry!",
    },
    snackbar: {
      success: "Done successfully",
    },
    buyGift: {
      name: "Enter your name",
      email: "Enter your email",
      quantity: "Quantity",
      hideData: "Keep my details a surprise",
      hideDataUntil: "Keep my details a surprise until",
      date: "Choose date",
      buyGiftDialogTitle: "Thank you for purchasing this gift",
      buyGiftDialogContent: "Would you like to mark another gift as purchased?",
      giftName:"Buy the"
    },
    createRegistry: {
      createRegistryDialogTitle: "It's time to create your own registry.",
      createRegistryDialogContent: "Do you want to create it?",
    },
  },
};
