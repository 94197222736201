import { call, put } from "redux-saga/effects";
import * as API from "./APIs";
import * as ACTIONS from "./actions";
import { takeLatest } from "redux-saga/effects";
import * as TYPES from "./constants";
import { dispatchSnackbarError } from "../../utils/Shared";

// Replace with your sagas
export function* getGifterRegistryList({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(API.getGifterRegistryList, payload.id);
    yield put(ACTIONS.gifterRegistryListReceive(data));
  } catch (err) {
    console.log("err", err);
    dispatchSnackbarError(err.response.data.data.message)
  }
}
export function* getGifterRegistryDetails({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(API.getGifterRegistryDetails, payload.id);
    yield put(ACTIONS.gifterRegistryDetailsReceive(data));
    localStorage.setItem("wishlistId",data.wishlist_uuid)
  } catch (err) {
    console.log("err", err);
    dispatchSnackbarError(err.response.data.error)
  }
}
export function* purchaseGift({ payload }) {
  try {
    const {
      data: {
        data: { name },
      },
    } = yield call(API.purchaseGift, payload);
    payload.setOpenPurchaseDialog(true)
  } catch (err) {
    console.log("err", err);
    (dispatchSnackbarError(err.response.data.errors[0].error||err.response.data.error||err.response.data.data.message))
  }
}

export function* GifterRegistrySaga() {
  yield takeLatest(TYPES.GET_GIFTER_REGISTRY_LIST, getGifterRegistryList);
  yield takeLatest(TYPES.GET_GIFTER_REGISTRY_DETAILS, getGifterRegistryDetails);
  yield takeLatest(TYPES.PURCHASE_GIFT, purchaseGift);
}
