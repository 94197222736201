import { combineReducers } from "redux";
import lang from "../Lang/LangReducer";
import loader from "../Loader/LoaderReducer";
import snackbar from "../Snackbar/SnackbarReducer";
import Feature1 from "../Feature1/FeatureReducer";
import gifterRegistry from "../GifterRegistry/reducers";
import {navbar} from "../Navbar/reducers";

export default combineReducers({
  lang,
  loader,
  snackbar,
  Feature1,
  gifterRegistry,
  navbar
});
