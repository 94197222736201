export default {
  ar: {
    hello: "مرحبا",
    errors: {
      min3char: "لابد ان يكون على الاقل ثلاثة احرف في خانة الاسم",
      emailRegex: "من فضلك ادخل بريد الكتروني صحيح",
      minDateError: "التاريخ يجب ان يكون في المستقبل",
    },
    langBtn: "En",
    common: {
      rs: "ر.س",
      purchased: "مباع",
      need: "الهدية",
      item: "طلبات",
      items: "طلبات",
      yes: "نعم",
      no: "لا شكرا",
      buy:"شراء",
      link:"الرابط" 
    },
    home: {
      content:
        'لوريم إيبسوم(Lorem Ipsum) هو ببساطة نص شكلي (بمعنى أن الغاية هي الشكل وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر. كان لوريم إيبسوم ولايزال المعيار للنص الشكلي منذ القرن الخامس عشر عندما قامت مطبعة مجهولة برص مجموعة من الأحرف بشكل عشوائي أخذتها من نص، لتكوّن كتيّب بمثابة دليل أو مرجع شكلي لهذه الأحرف. خمسة قرون من الزمن لم تقضي على هذا النص، بل انه حتى صار مستخدماً وبشكله الأصلي في الطباعة والتنضيد الإلكتروني. انتشر بشكل كبير في ستينيّات هذا القرن مع إصدار رقائق "ليتراسيت" (Letraset) البلاستيكية تحوي مقاطع من هذا النص، وعاد لينتشر مرة أخرى مؤخراَ مع ظهور برامج النشر الإلكتروني مثل "ألدوس بايج مايكر" (Aldus PageMaker) والتي حوت أيضاً على نسخ من نص لوريم إيبسوم.',
    },
    splashScreen: {
      content: "انتظر الموقع قريبا...",
    },
    registryList: {
      purchased: "مباع",
      noGifts: "لم يتم اضافة طلبات بعد",
      createWishList: "تبي قائمة مثل هذي؟ اضغط هنا",
    },
    snackbar: {
      success: "تم بنجاح",
    },
    buyGift: {
      name: "الاسم",
      email: "البريد الالكتروني",
      quantity: "العدد",
      hideData: "اجعل بياناتي مفاجأة",
      hideDataUntil: "اجعل بياناتي مفاجاءة حتي يوم",
      date: "اختر التاريخ",
      buyGiftDialogTitle: "شكرا لشرائك هذه الطلبات",
      buyGiftDialogContent: "هل تود ان تشتري طلبات اخرى؟",
      giftName:"اشتري ال"
    },
    createRegistry: {
      createRegistryDialogTitle: ".حان الوقت لإنشاء السجل الخاص بك",
      createRegistryDialogContent: "هل تريد انشائه؟",
    },
  },
};
