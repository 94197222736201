import * as TYPES from "./constants";

export const getGifterRegistryList = (payload) => ({
  type: TYPES.GET_GIFTER_REGISTRY_LIST,
  payload,
});
export const gifterRegistryListReceive = (payload) => ({
  type: TYPES.GIFTER_REGISTRY_LIST_RECEIVE,
  payload,
});
export const getGifterRegistryDetails = (payload) => ({
    type: TYPES.GET_GIFTER_REGISTRY_DETAILS,
    payload
})
export const gifterRegistryDetailsReceive = (payload) => ({
    type: TYPES.GIFTER_REGISTRY_DETAILS_RECEIVE,
    payload
})
export const purchaseGift = (payload) => ({
  type: TYPES.PURCHASE_GIFT,
  payload
})
export const gifteeNamePurchased = (payload) => ({
  type: TYPES.GIFTEE_NAME_PURCHASED,
  payload
})