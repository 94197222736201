import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentLang } from "../../store/Lang/LangAction";
import { Avatar } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import messages from "./../../assets/Local/messages";
import { Btn } from "../Controls/Button/Button";
import History from "../../routes/History";
import { back, logo } from "../../utils/images";
import { useWindowDimensions } from "../../utils/hooks/ScreenWidthHook";
import "./Navbar.scss";

export default function Navbar({ content }) {
  const {
    lang,
    navbar: { title, step, step2, step3, step4 },
  } = useSelector((state) => state);
  const { width } = useWindowDimensions();
  const breakpoint = 620;
  const dispatch = useDispatch();
  const message = messages[lang];
  const switchLanguage = (lang) => {
    dispatch(setCurrentLang(lang === "ar" ? "en" : "ar"));
  };
  const handleNavbarNavigation = () => {
    switch (step) {
      case 2:
        return History.push(`/${lang}`);
      case 3:
        return History.push(`/${lang}/?uuid=${step2.id}`);
      case 4:
        return History.push(`/${lang}/gift-details/${step3.id}`);
      default:
        break;
    }
  };
  return (
    <>
      <nav className="navbar navbar-bg py-0">
        {step > 2 && width < breakpoint && lang == "en" && (
          <IconButton onClick={handleNavbarNavigation} color={"#fff"}>
            <img src={back} alt="back-icon" />
          </IconButton>
        )}
        <Avatar variant="square" className="logo">
          <img src={logo} alt="logo" className="w-100" />
        </Avatar>
        <p
          className={`p-0 m-0 font-17 ${"font-medium"} white-color w-50 justify-content-center mx-auto d-flex`}
        >
          {step == 4 ? `${message.buyGift.giftName} ${title}` : title}
        </p>
        <div className="d-flex align-items-center">
          <Btn
            onClick={() => switchLanguage(lang)}
            text={message.langBtn}
            classes={`lang-btn rounded-0 ${
              lang == "ar" ? "border-right" : "border-left"
            }`}
          />
        </div>
        {step > 2 && width < breakpoint && lang == "ar" && (
          <IconButton onClick={handleNavbarNavigation} color={"#fff"}>
            <img src={back} alt="back-icon" />
          </IconButton>
        )}
      </nav>
    </>
  );
}
