import store from "../store";
import { showSnackbarAction } from "../store/Snackbar/SnackbarAction";
import messages from "../assets/Local/messages";

// Get query from url
export const getURLParams = (paramName) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(paramName);
};

// To show error message that returned from backend
export function dispatchSnackbarError(data) {
  if (data) {
    const errorMsg = data;
    store.dispatch(showSnackbarAction(errorMsg, "error"));
  }
}
// To show success message after any success request if needed and rendered from locale files
export function dispatchSnackbarSuccess(message) {
  const lang = store.getState().lang;
  store.dispatch(
    showSnackbarAction(messages[lang].snackbar[message], "success")
  );
}

export const sentenceTrim = (data, trimLength, className) => {
  return data && data.length > trimLength ? (
     `${data.slice(0, trimLength)}...`
  ) : (
    data
  );
};


