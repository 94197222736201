import React from "react";
import Button from "@material-ui/core/Button";

export const Btn = ({ text, onClick, type, classes, disabled, ...rest }) => {
  return (
    <Button
      variant="contained"
      type={type}
      onClick={onClick}
      className={classes}
      disabled={disabled}
      {...rest}
    >
      {text}
    </Button>
  );
};
