import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { StylesProvider, jssPreset } from "@material-ui/styles";
import rtl from "jss-rtl";
import { create } from "jss";
import App from "./containers/App";
import { theme } from "./utils/Theme";

function ThemeApp() {
  const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
  const lang = useSelector((state) => state.lang);
  const [direction, setDirection] = useState(lang === "en" ? "ltr" : "rtl");

  useEffect(() => {
    setDirection(lang === "en" ? "ltr" : "rtl");
    if (lang == "ar") {
      document.body.classList.add("rtl");
    } else {
      document.body.classList.remove("rtl");
    }
  }, [lang]);

  const themeProvider = theme({direction,lang})
  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={themeProvider}>
        <App />
      </ThemeProvider>
    </StylesProvider>
  );
}

export default ThemeApp;
