import * as TYPES from "./constants";

const initialState = {
  list: {},
  details: {},
  gifteeName: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GIFTER_REGISTRY_LIST_RECEIVE:
      return { ...state, list: action.payload };
    case TYPES.GIFTER_REGISTRY_DETAILS_RECEIVE:
      return { ...state, details: action.payload };
    case TYPES.GIFTEE_NAME_PURCHASED:
      return { ...state, gifteeName: action.payload };
    default:
      return state;
  }
};
