import * as types from "./LoaderTypes";

const INITIAL_STATE = {
  loading:false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SHOW_LOADER:
      return {...state,loading:action.payload};
    default:
      return state;
  }
};
