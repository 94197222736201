import { createMuiTheme } from "@material-ui/core/styles";

export const theme = ({ direction, lang }) =>
  createMuiTheme({
    direction: direction,
    palette: {
      primary: {
        main: "#282828",
      },
      secondary: {
        main: "#ADADAD",
      },
    },
    typography: {
      fontFamily: [
        "regularFont",
        "mediumFont",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      boldFont: {
        fontFamily: "bold",
      },
      semiBoldFont: {
        fontFamily: "semi-bold",
      },
      mediumFont: "medium",
      font600Weight: 600,
      fontNormalStyle: "normal",
    },
    overrides: {
      MuiTextField: {
        root: {
          "& .MuiInputBase-input": {
            fontFamily: lang=="en"?"regularFont":"ArabicRegularFont",
          },
          "& label": {
            color: "#ADADAD",
            fontSize: 17,
            fontFamily: lang=="en"?"regularFont":"ArabicRegularFont",
          },
          "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottom: "2px solid #908e8e",
          },
          "& .MuiInputBase-input.Mui-disabled": {
            color: "#000000",
          },
        },
      },
      MuiDialog: {
        root: {
          borderRadius: 0,
          "& .MuiPaper-rounded": {
            borderRadius: "unset",
            padding: "20px",
          },
        },
      },
      MuiDialogTitle: {
        root: {
          color: "#282828",
          textAlign: "center",
          "& h6": {
            fontSize: 20,
            fontFamily: lang=="en"?"mediumFont !important":"ArabicboldFont !important",
          },
        },
      },
      MuiDialogContent: {
        root: {
          color: "#ADADAD",
          textAlign: "center",
          paddingTop: "unset !important",
          "& p": {
            fontSize: 17,
            fontFamily: lang=="en"?"regularFont":"ArabicRegularFont",
          },
        },
      },
      MuiDialogActions: {
        root: {
          display: "unset",
          "& .MuiButton-root": {
            minWidth: "100%",
            height: "50px",
            "& .MuiButton-label": {
              fontSize: 17,
              fontFamily: lang=="en"?"regularFont":"ArabicRegularFont",
            },
          },
        },
      },
      MuiBackdrop: {
        root: {
          backgroundColor: "RGBA(40,40,40,0.8)",
        },
      },
      MuiButton: {
        root: {
          minHeight: 50,
          "& .MuiButton-label": {
            fontSize: 17,
            textTransform: "none !important",
            fontFamily: lang=="en"?"regularFont":"ArabicRegularFont",
          },
        },
      },
      MuiSkeleton: {
        root: {
          backgroundColor: "#F7F7F7",
          borderRadius: "0 !important",
        },
      },
      MuiMobileStepper: {
        root: {
          justifyContent: "space-between",
          padding: "20px 0",
          background: "#fff",
          "& .MuiMobileStepper-dot": {
            borderRadius: 0,
            border: "1px solid #ADADAD",
            padding: "5px",
            backgroundColor: "#fff",
          },
          "& .MuiMobileStepper-dotActive": {
            backgroundColor: "#ADADAD",
          },
        },
      },
      MuiCircularProgress: {
        root: {
          width: 40,
          height: 40,
          position: "fixed",
          left: 0,
          right: 0,
          margin: "auto",
          top: 0,
          zIndex: 1000,
          bottom: 0,
        },
      },
    },
  });
