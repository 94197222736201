import * as TYPES from "./constants";

const initialState = {
  step2: { id: null },
  step3: { id: null },
  step4: { id: null },
  step1: { id: null },
  title: null,
  step: null,
};

export const navbar = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.NAVBAR_TITLE:
      return {
        ...state,
        [action.payload.stepName]: { id: action.payload.id },
        step: action.payload.stepNumber,
        title: action.payload.title,
      };
    default:
      return state;
  }
};
