import { createStore, applyMiddleware, compose } from "redux";
import reducers from "./reducers";
import createSagaMiddleware from "redux-saga";
import { persistStore,persistReducer } from "redux-persist";
import { watchSagas } from "./sagas";
import Storage from "redux-persist/lib/storage";
const saga = createSagaMiddleware();
//redux dev tool
const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;
const enhancer = composeEnhancers(applyMiddleware(saga));
const persistConfig = {
  key: 'root',
  storage: Storage,
  whitelist: ['navbar']
  };
const persisedtReducer = persistReducer(persistConfig, reducers)
const store = createStore(persisedtReducer, enhancer);
export const persistor = persistStore(store);
saga.run(watchSagas);

export default store;
