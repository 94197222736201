import { axiosInstance } from "../../network/apis";
const handlerEnabled = false;

export const getGifterRegistryList = async (id) => {
  return await axiosInstance.get(`/guests/wishlists/${id}`);
};

export const getGifterRegistryDetails = async (id) => {
  return await axiosInstance.get(`/guests/items/${id}`);
};

export const purchaseGift = async (payload) => {
  return await axiosInstance.post(`/guests/items/${payload.id}`,{...payload.values});
};

export const getGifteeInfo = async (payload) => {
  return await axiosInstance.get(`/guests/gifters/${payload}`);
};